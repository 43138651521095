.HowWeHelp {
  .card {
    height: 100%;
    box-shadow: 0 0 23px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: none;
    }
  }
}
