.footer {
  background-color: var(--tertiary);

  .footer-logo {
    background-color: transparent;
    border: 0;
    width: 270px;
    text-align: left;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }

    .image {
      width: 50px;
      height: 50px;
      content: url(../../img/iCreW_fabicon.png);
      float: left;
    }

    .head {
      display: block;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.2;
      letter-spacing: 5px;

      .iLetter {
        color: #f17b27;
        /* font-style: italic; */
      }
    }

    .quote {
      display: block;
      font-size: 0.6rem;
      letter-spacing: 0;
      float: left;
    }
  }

  .paragraph {
    font-size: 0.9rem;
    font-weight: 200;
  }

  .heading {
    color: #ecb48c;
    font-size: 1.15rem;
  }

  .secondBox {
    p {
      font-size: 0.9rem;
      font-weight: 200;
    }
  }

  .thirdBox {
    .quicklink {
      font-size: 0.9rem;
      font-weight: 200;
      display: block;
      text-align: left;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}

.footer-black {
  background-color: #131415;

  .copyright {
    font-size: 0.9rem;
  }

  .social-icon {
    width: 26px;
    height: 26px;
    font-size: 0.7rem;
  }
}
