.callback {
  position: relative;
}

.callback::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  background: linear-gradient(
      rgba(228, 237, 244, 0.95),
      rgba(228, 237, 244, 0.95)
    ),
    url(../../../img/insights_bg.png);
  z-index: -1;
}
