.header {
  transition: 0.3s;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 12px;
  vertical-align: middle;
  margin-left: 8px;
}

.container-fluid .navbar-brand img {
  height: 61px;
}

.container-fluid .navbar {
  & .navbar-nav {
    .nav-item {
      .nav-link {
        text-decoration: underline;
        text-underline-offset: 0.5rem;
        -webkit-text-decoration-color: transparent;
        -moz-text-decoration-color: transparent;
        text-decoration-color: transparent;

        padding: 25px 15px;
        color: var(--tertiary);
        font-weight: 600;
        outline: none;
        transition: all 0.5s ease-in-out;

        &:hover {
          -webkit-text-decoration-color: var(--tertiary);
          -moz-text-decoration-color: var(--tertiary);
          text-decoration-color: var(--tertiary);
        }
      }
    }

    .nav-link:hover,
    .nav-link.active {
      color: var(--primary);
    }

    .dropdown-menu .dropdown-item:hover,
    .dropdown-menu .dropdown-item:focus {
      color: var(--tertiary);
      background-color: #e9ecef;
    }

    .social .rounded-circle.btn {
      background-color: #dfe4fd;

      &:hover,
      &:focus {
        background-color: #f8f9fa;
      }
    }
  }
}

/* Mobile view */
@media (max-width: 991.98px) {
  .container-fluid {
    .navbar-brand img {
      height: 41px;
    }

    .navbar-toggler {
      i {
        color: var(--tertiary);
        font-size: 1.125rem;
      }
    }

    .navbar .navbar-nav {
      margin-top: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.07);
      background-color: #ffffff;

      .nav-item {
        .dropdown-menu {
          border-color: inherit;
          padding: 0;
        }
        .nav-link {
          padding: 10px 0;
        }
      }
    }
  }

  .page {
    margin-top: 57px;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    visibility: hidden;
    top: 100%;
    transform: rotateX(-50deg);
    transform-origin: 0% 0%;
    transition: 0.3s;
    opacity: 0;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    opacity: 1;
  }

  .page {
    margin-top: 119px;
  }
}
