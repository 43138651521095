.testimonial {
  .card {
    color: var(--tertiary);

    .text-muted {
      font-size: 0.9rem;
    }

    .descContainer {
      --max-lines: 5;
      --lh: 1.4rem;

      .truncate-overflow {
        position: relative;
        max-height: calc(var(--lh) * var(--max-lines));
        overflow: hidden;

        &.active {
          max-height: initial;
          overflow: initial;
        }
      }

      .readmore {
        font-size: 0.6rem;
        opacity: 0.8;
      }
    }
  }

  .my-blue-btn.arrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    &[disabled] {
      opacity: 0.3;
    }
  }

  .rec-pagination {
    button {
      background-color: rgba(20, 59, 88, 0.7);
      box-shadow: rgb(20, 59, 88) 0px 0px 1px 3px;

      &.rec-dot_active {
        opacity: 0.3;
        background-color: rgba(20, 59, 88, 1);
        width: 22px;
        border-radius: 5px;
        cursor: default;
      }
    }
  }
}
