#root .videoBannerCont {
  position: relative;
  overflow: hidden;

  #video-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;

    video {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }

  #video-desc {
    position: relative;
    z-index: 1;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.9) 0%,
      transparent 100%
    );

    .desc {
      h1 {
        color: var(--tertiary);
        font-weight: 500;
      }
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    #video-desc {
      .desc {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important;
      }
    }
  }

  /* X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1400px) {
    #video-desc {
      .desc {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
      }
    }
  }

  /* XX-Large devices (larger desktops, 1400px and up) */
  @media (min-width: 1600px) {
    #video-desc {
      .desc {
        padding-top: 15rem !important;
        padding-bottom: 15rem !important;
      }
    }
  }
}
