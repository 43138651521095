#root {
  .scheduleAppoform {
    @media (min-width: 992px) {
      .scheduleappo {
        width: 90% !important;
      }
    }

    form {
      & label {
        font-size: 0.9rem;
        color: var(--tertiary);
      }

      .hintlabel {
        font-size: 0.8rem;
      }
      .form-control {
        color: var(--tertiary);
      }

      .react-select-container {
        z-index: 3;
        height: 100%;

        .react-select__placeholder {
          font-size: 0.9rem;
          color: var(--tertiary);
        }

        .react-select__control {
          height: 100%;
          min-height: initial;
          border-color: #dee2e6;
          border-width: 1px;
        }
      }

      &.was-validated {
        .react-select-container {
          &.dtfull {
            .react-select__control {
              border-color: #198754;
              &.react-select__control--is-focused {
                box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
              }
            }
          }
          &.empt {
            .react-select__control {
              border-color: #dc3545;
              &.react-select__control--is-focused {
                box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
              }
            }
          }
        }
      }
    }
  }
}
