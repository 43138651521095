#root {
  .ourOfficeLocation {
    @media (max-width: 992px) {
      .pgraph {
        font-size: 0.85rem;
      }
    }

    @media (max-width: 768px) {
      .pgraph {
        font-size: 0.84rem;
      }
    }
  }
}
