:root {
  --primary: #143b58;
  --secondary: #e93c05;
  --tertiary: #143b58; /* top-header-bg */
  --light: #dfe4fd;
  --dark: #011a41;

  --top-bg: #143b58;

  /* --font-family-base: ; */
}

#root {
  /* .gowun-batang-regular {
    font-family: "Gowun Batang", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .gowun-batang-bold {
    font-family: "Gowun Batang", serif;
    font-weight: 700;
    font-style: normal;
  } */

  /* h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Gowun Batang', serif;
    font-style: normal;
  } */

  /* .montserrat-<uniquifier> {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
  } */
  p {
    font-family: 'Montserrat', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

  .btn {
    transition: 0.5s;
    font-weight: 500;
  }

  .btn-primary,
  .btn-outline-primary:hover {
    color: var(--light);
  }

  .btn-square {
    width: 38px;
    height: 38px;
  }

  .btn-sm-square {
    width: 32px;
    height: 32px;
  }

  .btn-lg-square {
    width: 48px;
    height: 48px;
  }

  .btn-square,
  .btn-sm-square,
  .btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
  }

  .my-blue-text {
    color: var(--tertiary);
  }

  .my-blue-btn {
    background-color: var(--tertiary);
    border-color: var(--tertiary);

    &:hover {
      background-color: #1e6496;
      border-color: #1e6496;
    }
  }
}
/* 
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
 */
