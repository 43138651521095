.stepProcess {
  .circle {
    padding: 13px 20px;
    border-radius: 50%;
    max-height: 50px;
    background-color: rgb(206, 216, 225);
    z-index: 2;
  }

  .step-container.row {
    .col-2 {
      align-self: stretch;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        border-left: 3px solid rgb(206, 216, 225);
        z-index: 1;
      }

      &.bottom::after {
        height: 50%;
        left: 50%;
        top: 50%;
      }

      &.full::after {
        height: 100%;
        left: calc(50% - 3px);
      }

      &.top::after {
        height: 50%;
        left: 50%;
        top: 0;
      }

      &.top-bottom::after {
        left: 50%;
      }

      &.top-only::after {
        height: 50%;
        left: calc(50% - 3px);
        top: 0;
      }
    }

    .hoverEffect {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.1);
      /* border-color: transparent; */
      transition: all 0.3s ease-in-out;

      /* &.text-right {
        text-align: right !important;

        h5 {
          &:after {
            left: 0;
            right: initial;
          }
        }
      } */

      &:hover {
        box-shadow: none;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.175);

        & h5:after {
          opacity: 0.2;
        }
      }

      h5 {
        position: relative;
        &:after {
          position: absolute;
          top: 10px;
          right: 0;
          font-size: 2.5em;
          line-height: 0.1em;
          vertical-align: -0.4em;
          opacity: 0.1;
          transition: all 0.3s ease-in-out;
        }

        &.s:after {
          content: 'S';
        }
        &.t:after {
          content: 'T';
        }
        &.e:after {
          content: 'E';
        }
        &.p:after {
          content: 'P';
        }
      }
    }
  }

  .timeline {
    div {
      padding: 0;
      height: 40px;
    }

    hr {
      border-top: 3px solid rgb(206, 216, 225);
      margin: 0;
      top: 17px;
      position: relative;
      opacity: 1;
    }

    .col-2 {
      display: flex;
      overflow: hidden;
    }

    .corner {
      border: 3px solid rgb(206, 216, 225);
      width: 100%;
      position: relative;
      border-radius: 15px;
    }

    .top-right {
      left: 50%;
      top: -50%;
    }
    .left-bottom {
      left: -50%;
      top: calc(50% - 3px);
    }
    .top-left {
      left: -50%;
      top: -50%;
    }
    .right-bottom {
      left: 50%;
      top: calc(50% - 3px);
    }
  }
}
